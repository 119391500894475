<script>
  /**
   * A layout component that arranges its children vertically with gaps between them, by injecting a `mt-*` class to every child but the first.
   */
  export default {
    props: {
      /** The HTML tag used for the root element. */
      tag: {
        type: String,
        default: 'div',
      },
      /** The size of the gap between child elements. */
      gapSize: {
        type: String,
        default: 'md',
        validator: (value) => ['none', 'sm', 'md', 'lg'].includes(value),
      },
      /** The HTML tag used to wrap each child element. Defaults to undefined which means child elements are not wrapped. Can be a function which will be executed for each child (passing `nodeIndex` and `nodeCount`) and return the tag for that child’s wrapper. */
      childrenWrapperTag: {
        type: [String, Function],
        default: undefined,
      },
    },
    computed: {
      marginTopClass() {
        switch (this.gapSize) {
          case 'sm':
            return 'mt-1'
          case 'md':
            return 'mt-2'
          case 'lg':
            return 'mt-4'
          default:
            return ''
        }
      },
      childrenClassesFunction() {
        return ({ nodeIndex }) => (nodeIndex > 0 ? this.marginTopClass : undefined)
      },
    },
  }
</script>

<template>
  <UtilityRender
    :tag="tag"
    class="flex flex-col"
    :children-wrapper-tag="childrenWrapperTag"
    :children-wrapper-classes="childrenWrapperTag ? childrenClassesFunction : undefined"
    :children-classes="childrenWrapperTag ? undefined : childrenClassesFunction"
  >
    <slot />
  </UtilityRender>
</template>
